import createSvgIcon from "@mui/material/utils/createSvgIcon";

const Ban = createSvgIcon(
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
  <g clipPath="url(#clip0_936_56398)">
  <path d="M4.48918 11.878L3.86263 14.217L1.57258 14.2654C0.888195 12.996 0.5 11.5437 0.5 10.0003C0.5 8.50793 0.862953 7.10055 1.50631 5.86133H1.5068L3.54559 6.23511L4.4387 8.26166C4.25177 8.80661 4.14989 9.39161 4.14989 10.0003C4.14996 10.661 4.26963 11.294 4.48918 11.878Z" fill="#FBBB00" />
  <path d="M18.3419 8.31836C18.4453 8.86279 18.4992 9.42504 18.4992 9.99967C18.4992 10.644 18.4314 11.2725 18.3024 11.8788C17.8642 13.9419 16.7194 15.7434 15.1336 17.0182L15.1331 17.0177L12.5651 16.8867L12.2017 14.6179C13.254 14.0007 14.0764 13.035 14.5096 11.8788H9.69702V8.31836H14.5798H18.3419Z" fill="#518EF8" />
  <path d="M15.1333 17.0183L15.1338 17.0188C13.5915 18.2585 11.6322 19.0003 9.49944 19.0003C6.07202 19.0003 3.09214 17.0846 1.57202 14.2654L4.48862 11.8779C5.24866 13.9064 7.20542 15.3503 9.49944 15.3503C10.4855 15.3503 11.4092 15.0838 12.2019 14.6185L15.1333 17.0183Z" fill="#28B446" />
  <path d="M15.2457 3.07197L12.3301 5.45894C11.5097 4.94615 10.5399 4.64992 9.50101 4.64992C7.15507 4.64992 5.16171 6.16013 4.43974 8.26131L1.50782 5.86098H1.50732C3.00519 2.97307 6.02265 1 9.50101 1C11.6847 1 13.687 1.77787 15.2457 3.07197Z" fill="#F14336" />
  </g>
  <defs>
  <clipPath id="clip0_936_56398">
  <rect width="18" height="18" fill="white" transform="translate(0.5 1)" />
  </clipPath>
  </defs>
  </svg>, "Ban"
);

export default Ban;
