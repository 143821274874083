import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import "nprogress/nprogress.css";
import App from "./App";
import { AuthProvider } from "./contexts/JWTContext";
import store from "./store";
// import { SettingsProvider } from "./contexts/SettingsContext";
// import { FirestoreProvider } from "./contexts/FirebaseFirestoreContext";

if (process.env.REACT_APP_ENV === "prod") console.log = function noConsole() {};

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <AuthProvider>
                <App />
              </AuthProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root")
);
