import axios from "axios";
import Auth from "./auth";

axios.interceptors.response.use(
  (response) => response,
  (e) => {
    const error = { ...e };
    if (error.response) {
      if (error.response.status === 401) {
        // Handle if auth failed
        // Auth.logout();
        window.location.reload();
      } else if (error.response.status === 404 && error.response.data.message) {
        // Handle 404
      } else if (error.response.status === 409) {
        // Handle error in request
      } else if (error.response.status === 422) {
        // Handle data error
      } else if (error.response.status === 500) {
        // Handle server Error
        // error.response.data.error will have the error details
      } else {
        // Something else
      }
    } else {
      // Something happened in setting up the request that triggered an Error
    }
    // Do something with request error
    error.hasError = true;
    return Promise.resolve(error);
  }
);
export const apiService = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});
export const authService = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

authService.interceptors.request.use((config) => {
  const headers = {
    Accept: "application/json",
  };
  // console.log("IN AUTH SERVICE", headers);
  return { ...config, headers };
});
apiService.interceptors.request.use(async (config) => {
  const authHeaders = await Auth.getAuthHeaders();
  const headers = {
    Accept: "application/json",
    ...(config.method === "post" && { "Content-Type": "multipart/form-data" }),
    ...authHeaders,
  };
  // console.log("IN API SERVICE", headers);
  return { ...config, headers };
});

apiService.interceptors.response.use(
  (response) => response,
  (e) => {
    const error = { ...e };
    if (error.response.status === 401) {
      Auth.logout();
      // window.location.reload();
    } else {
      error.hasError = true;
    }
    return Promise.reject(error);
  }
);

export default {
  authService,
  apiService,
};
