import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate, useLocation } from "react-router";
import useAuth from "../hooks/useAuth";
import Login from "../pages/authentication/Login";

const AuthGuard = (props) => {
  const { children } = props;
  const location = useLocation();
  const auth = useAuth();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  if ((auth.user.role === 5 || auth.user.role === 6) && !(location.pathname.startsWith("/ingredients") || location.pathname.startsWith("/daily-values"))) {
    return <Navigate to="/ingredients" />;
  }
  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
