import { apiService } from "../utils/axios";
import urls from "../constants/urls";

const getData = () => apiService
  .get(urls.ACCOUNT())
  .then((res) => (res.data));

export default {
  getData
};
