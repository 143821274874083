import { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Badge, Box, Button, Collapse, ListItem } from "@mui/material";
import { experimentalStyled } from "@mui/material/styles";
import ChevronDownIcon from "../icons/ChevronDown";
import ChevronRightIcon from "../icons/ChevronRight";

const StyledBadge = experimentalStyled(Badge)(() => ({
  ".MuiBadge-badge": {
    fontSize: 10,
    height: 16,
    width: 16,
    minWidth: 16,
    padding: "0 4px",
    top: 2,
    right: 2,
  }
}));
const StyledButton = experimentalStyled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ".MuiBox-root": {
    fontWeight: "600",
  },
  ":hover": {
    backgroundColor: theme.palette.primaryLight.main,
    ".MuiBox-root": {
      color: theme.palette.primary.main,
    },
    "& svg": {
      color: theme.palette.primary.main
    },
  },
  "&.active": {
    backgroundColor: theme.palette.background.active,
    ".MuiBox-root": {
      color: theme.palette.primary.main,
    },
    "& svg": {
      color: theme.palette.primary.main,
    },
    ":hover": {
      backgroundColor: theme.palette.background.active,
    }

  }
}));

const NavItem = (props) => {
  const { active, children, depth, icon, info, open: openProp, path, title, count, drawerOpen, ...other } = props;
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          py: 0
        }}
        {...other}
      >
        <Button
          endIcon={!open ? <ChevronRightIcon fontSize="small" />
            : <ChevronDownIcon fontSize="small" />}
          onClick={handleToggle}
          startIcon={<StyledBadge color="primary" badgeContent={count || 0} max={99}>{icon}</StyledBadge>}
          sx={{
            color: "text.secondary",
            fontWeight: "fontWeightMedium",
            justifyContent: "flex-start",
            pl: `${paddingLeft}px`,
            pr: "8px",
            py: "12px",
            textAlign: "left",
            textTransform: "none",
            width: "100%",
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
          {info}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        pt: 0,
        justifyContent: drawerOpen ? "flex-start" : "center"
      }}
    >
      <StyledButton
        component={path && RouterLink}
        startIcon={<StyledBadge color="primary" badgeContent={count || 0} max={99}>{icon}</StyledBadge>}
        sx={{
          color: "text.secondary",
          fontWeight: "fontWeightMedium",
          textAlign: "left",
          pl: `${paddingLeft}px`,
          pr: drawerOpen ? 2 : 0,
          py: "12px",
          textTransform: "none",
          width: "100%",
          justifyContent: drawerOpen ? "flex-start" : "center"
          // ...(active && {
          //   backgroundColor: "background.active",
          //   color: "primary.main",
          //   fontWeight: "fontWeightBold",
          //   "& svg": {
          //     color: "primary.main"
          //   }
          // })
        }}
        variant="text"
        to={path}
      >
        {drawerOpen && (
          <>
            <Box sx={{ flexGrow: 1 }}>
              {title}
            </Box>
            {info}
          </>
        )}
      </StyledButton>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  drawerOpen: PropTypes.bool,
};

NavItem.defaultProps = {
  active: false,
  open: false
};

export default NavItem;
