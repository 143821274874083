import authApi from "../api/authApi";
import { getWithExpiry, setWithExpiry } from "./storage";

const getAuthHeaders = async () => {
  const refresh = localStorage.getItem("refresh");
  if (!refresh || refresh === "undefined") return {};

  let token = getWithExpiry("access");
  if (!token) {
    console.log("refreshing token");
    token = await authApi.refreshToken({ refresh }).then(({ data }) => data.access);
    setWithExpiry("access", token, 240000);
  }
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }
  return {};
};

const logout = () => {
  localStorage.removeItem("refresh");
  localStorage.removeItem("access");
};

export default {
  getAuthHeaders,
  logout
};
