import { CssBaseline, ThemeProvider } from "@mui/material";
import RTL from "./components/RTL";
import { createCustomTheme } from "./theme";
import { THEMES } from "./constants";
import routes from "./routes";
import { useRoutes } from "react-router";
import useAuth from "./hooks/useAuth";
import SplashScreen from "./components/SplashScreen";
import AppToaster from "./components/AppToaster";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
  const auth = useAuth();

  const theme = createCustomTheme({
    direction: "ltr",
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: THEMES.LIGHT
  });
  const content = useRoutes(routes);
  console.log("REACT_APP_GOOGLE_CLIENT_ID", process.env.REACT_APP_GOOGLE_CLIENT_ID);
  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <RTL direction="ltr">
          <CssBaseline />
          <AppToaster />
          {auth.isInitialized ? content : <SplashScreen />}
        </RTL>
      </GoogleOAuthProvider>;
      {/* {token != null && <Link token={token} />} */}
    </ThemeProvider>
  );
};

export default App;
