import toast from "react-hot-toast";
import { apiService } from "./axios";
import apiUtils from "./api";
import { MESSAGES } from "../constants";

export const axiosBaseQuery = ({ baseUrl } = { baseUrl: `${process.env.REACT_APP_API_HOST}/api/` }) => async ({ url, method, data }) => {
  try {
    let response = {};
    if (method === "get") {
      const qs = data ? apiUtils.getQueryString(data) : "";
      response = await apiService.get(baseUrl + url + qs).then((res) => (res.data));
    }
    if (method === "post") {
      response = await apiService.post(baseUrl + url, data).then((res) => (res.data));
    }
    if (method === "put") {
      response = await apiService.put(baseUrl + url, data).then((res) => (res.data));
    }
    if (method === "delete") {
      response = await apiService.delete(baseUrl + url, data).then((res) => (res.data));
    }
    if (method === "blob") {
      response = await apiService.post(baseUrl + url, data, { responseType: "blob" }).then((res) => (res.data));
    }
    return { data: response };
  } catch (error) {
    console.log("error", data, method, error);
    if (error.response) {
      if (error.response.status === 401) {
        window.location.reload();
      } else if (error.response.status === 404 && error.response.data.message) {
        // Handle 404
      } else if (error.response.status === 409) {
        // Handle error in request
      } else if (error.response.status === 422) {
        // Handle data error
      } else if (error.response.status === 500) {
        // Handle server Error
        // error.response.data.error will have the error details
      } else {
        // Something else
      }
    } else {
      // Something happened in setting up the request that triggered an Error
    }
    if (method === "get") {
      toast.error(MESSAGES.API_FETCH_ERROR);
    }
    // Do something with request error
    return {
      error: { status: error.response?.status, data: error.response?.data },
    };
  }
};
