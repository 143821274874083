import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../utils/axiosBaseQuery";

export const getProvidesIDTags = (results, tag) => (results ? [...results.map(({ id }) => ({ type: tag, id })), tag] : [tag]);

export const baseApi = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Quote",
    "Ingredient",
    "Pricing",
    "User",
    "Invite",
    "ElementalAmount",
    "CaloricAmount",
    "DailyValue",
    "SalePriceRequest",
    "SalePriceRequestCount",
    "Activity",
    "Phase",
    "Card",
    "Customer",
    "Plastics",
  ],
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});
