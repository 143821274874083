import { Toaster } from "react-hot-toast";

import InfoIcon from "@mui/icons-material/InfoOutlined";

const AppToaster = () => (

  <Toaster
    position="bottom-center"
    containerStyle={{
      bottom: 50,
    }}
    toastOptions={{
      style: {
        borderRadius: 4,
        maxWidth: 1000,
        fontSize: 14,
        background: "#C8F2FA",
        border: "1px solid #0C1471",
        color: "#0C1471",
      },
      iconTheme: {
        primary: "#0C1471",
        secondary: "#C8F2FA",
      },
      success: {
        style: {
          background: "#edf7ed",
          border: "1px solid #09a911",
          color: "#087b0e"
        },
        iconTheme: {
          primary: "#09a911",
          secondary: "#edf7ed",
        },
      },
      error: {
        icon: <InfoIcon fontSize="small" />,
        style: {
          background: "#FCDFD0",
          border: "1px solid #CE1A1D",
          color: "#940D27",
        },
        iconTheme: {
          primary: "#940D27",
          secondary: "#FCDFD0",
        },
      },
      blank: {
        icon: <InfoIcon fontSize="small" />,
      }
    }}
  />
);

export default AppToaster;
