/* eslint-disable no-undef */
import { Suspense, lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import ErrorFallback from "./components/ErrorFallback";
import DashboardLayout from "./components/dashboard/DashboardLayout";

const Loadable = (Component) => (props) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  </ErrorBoundary>
);

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const Invite = Loadable(lazy(() => import("./pages/authentication/Invite")));

// Quotes pages

const QuotesList = Loadable(lazy(() => import("./pages/quotes/QuotesList")));
const AddQuote = Loadable(lazy(() => import("./pages/quotes/AddQuote")));
const EditQuote = Loadable(lazy(() => import("./pages/quotes/EditQuote")));
const SendQuote = Loadable(lazy(() => import("./pages/quotes/SendQuote")));
// const SampleRecipe = Loadable(lazy(() => import("./pages/quotes/SampleRecipe")));
const DailyValuesList = Loadable(lazy(() => import("./pages/dailyValues/DailyValuesList")));
const IngredientsList = Loadable(lazy(() => import("./pages/ingredients/IngredientsList")));
const PriceRequestsList = Loadable(lazy(() => import("./pages/priceRequests/PriceRequestsList")));
// const QuotePDFViewer = Loadable(lazy(() => import("./components/quotes/QuotePDFViewer")));
const PricingModel = Loadable(lazy(() => import("./pages/pricing/PricingModel")));
const ManageUsers = Loadable(lazy(() => import("./pages/account/ManageUsers")));
const ManageContacts = Loadable(lazy(() => import("./pages/contacts/ManageContacts")));
const SupplementFactsPDFViewer = Loadable(lazy(() => import("./components/supplementFacts/SupplementFactsPDFViewerPage")));
const Plastics = Loadable(lazy(() => import("./pages/pricing/PlasticsCosts")));

const NotFound = Loadable(lazy(() => import("./pages/NotFound")));

const routes = [
  {
    path: "user_auth",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "invite",
        children: [
          {
            path: ":inviteId",
            element: (
                <Invite />
            )
          }
        ]
      },
    ],
  }, {
    path: "pdf",
    children: [
      {
        path: ":quoteId",
        element: (
          <AuthGuard>
            <SupplementFactsPDFViewer />
          </AuthGuard>
        )
      },
    ]
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <QuotesList />,
      },
      {
        path: "quotes",
        children: [
          {
            path: "",
            element: <QuotesList />,
          },
          {
            path: "add",
            element: <AddQuote />,
          },
          {
            path: ":quoteId",
            children: [
              {
                path: "",
                element: <QuotesList />,
              },
              {
                path: "edit",
                element: <EditQuote />,
              },
              {
                path: "send",
                element: <SendQuote />,
              },
            ],
          }
        ]
      },
      {
        path: "daily-values",
        element: <DailyValuesList />,
      },
      {
        path: "ingredients",
        element: <IngredientsList />,
      },
      {
        path: "price-requests",
        element: <PriceRequestsList />,
      },
      {
        path: "pricing-model",
        element: <PricingModel />,
      },
      {
        path: "plastics",
        element: <Plastics />,
      },
      {
        path: "account",
        children: [
          {
            path: "users",
            element: <ManageUsers />
          }
        ]
      },
      {
        path: "contacts",
        element: <ManageContacts />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
