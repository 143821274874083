import createSvgIcon from "@mui/material/utils/createSvgIcon";

const ArrowHeadDown = createSvgIcon(
  <svg
    width="10"
    height="5"
    viewBox="0 0 10 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0L5 5L10 0H0Z" fill="#42526E" fillOpacity="0.86" />
  </svg>
);

export default ArrowHeadDown;
