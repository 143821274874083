import { snakeCase, camelCase } from "lodash";

// const defaultFields = [
//   "company_name",
//   "product_name",
//   "product_category",
//   "quote_type",
//   "quote_size",
//   "size",
//   "status",
//   "notes",
//   "quote_lock",
//   "quote_ingredients",
//   "custom_sale_prices",
//   "sale_prices",
//   "custom_ingredients",
//   "capsule_addons",
//   "powder_addons",
// ];

const multiValueOptions = [
  "quote_ingredients",
  "capsule_addons",
  "powder_addons",
  "quote_flavors",
  "custom_sale_prices",
  "sale_prices",
  "quote_custom_ingredients",
];

const otherOptions = {
  container_lid_options: [
    "container_size",
    "container_color",
    "lid_size",
    "label_size",
    "lid_style",
    "lid_color",
  ],
  powder_options: [
    "colors",
    "flavoring_type",
    "flavors",
    "label_size",
    "lid_style",
    "servings_per_container",
  ],
  quote_options: [
    "bottle_and_labour",
    "bottles_per_order",
    "capsules_cost",
    "commission_percentage",
    "cost_per_bottle",
    "cost_plus_commission",
    "loss_percentage",
    "total_kg",
    "total_mg",
  ],
  capsule_options: [
    "capsule_size",
    "capsule_type",
    "capsule_color",
    "serving_size",
    "servings_per_container",
    "capsules_per_container",
  ],
  cosmetics_options: [
    "cosmetics_color",
    "cosmetics_scent",
    "servings_per_container",
  ],
};

const getQueryString = (params) => `?${Object.keys(params)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
  .join("&")}`;

const formatKey = (data, func) => {
  const newData = {};
  Object.keys(data).forEach((key) => {
    newData[func(key)] = data[key];
  });
  return newData;
};

const toDeepFormat = (data, format) => {
  let caseFormat = snakeCase;
  if (format === "camel") caseFormat = camelCase;
  // console.log(caseFormat);
  const newObj = structuredClone(data);

  if (newObj) {
    Object.keys(newObj).forEach((key) => {
      if (typeof newObj[key] === "object" && newObj[key] != null) {
        if (Array.isArray(data[key])) {
          // needed to separate if block to avoid converting empty list into object
          if (typeof data[key][0] === "object") {
            const newArray = [];
            newObj[key].forEach((item) => {
              if (item != null) newArray.push(formatKey(item, caseFormat));
            });
            newObj[key] = newArray;
          }
        } else {
          newObj[key] = formatKey(data[key], caseFormat);
        }
      }
    });
  }
  const finalObj = formatKey(newObj, caseFormat);

  return finalObj;
};

const camelToSnakeCase = (data) => formatKey(data, snakeCase);
const snakeToCamelCase = (data) => formatKey(data, camelCase);

const stringToSnakeCase = (string) => String(string)
  .replace(/ - /g, "_")
  // replace space and slash with _
  .replace(/ |\//g, "_")
  .replace(/\./g, "")
  .replace(/%/g, "_percentage")
  .toLowerCase();

const transformIngredientImportData = (data) => {
  const result = {};
  const keys = Object.keys(data);

  keys.forEach((key) => {
    const newKey = key === "Is Artificial/Natural Flavor?" ? "is_flavor" : stringToSnakeCase(key);
    result[newKey] = data[key];
  });

  // Parsing Caloric Amount
  const CALORIC_KEYS = ["carbs", "fat", "cholesterol", "saturated_fat", "fiber", "protein", "sodium", "sugars"];
  const caloricAmount = {};

  CALORIC_KEYS.forEach((key) => {
    if (result[key] != null) caloricAmount[key] = result[key];
    delete result[key];
  });
  if (Object.keys(caloricAmount).length > 0) caloricAmount.misys_id = result.misys_id;

  result.caloric_amount = caloricAmount;

  // Parsing Elemental Amounts
  const ELEMENTAL_KEYS = ["element_vitamin", "compound", "percent_composition"];
  const elementalAmounts = [];

  // get indexes
  const elementalIndexes = keys.reduce((prev, curr) => {
    const parsedInt = parseInt(curr.split(" ")[0], 10);
    if (Number.isNaN(parsedInt) || prev.includes(parsedInt)) return prev;
    prev.push(parsedInt);
    return prev;
  }, []);

  elementalIndexes.forEach((index) => {
    const elementalAmount = {};
    ELEMENTAL_KEYS.forEach((key) => {
      // add validation/error if key is missing?
      if (`${index}_${key}` in result) {
        elementalAmount[key] = result[`${index}_${key}`];
        delete result[`${index}_${key}`];
      }
    });
    elementalAmount.misys_id = result.misys_id;

    elementalAmounts.push(elementalAmount);
  });

  result.elemental_amount = elementalAmounts;

  console.log("result", result);
  return result;
};

const transformQuoteData = (data) => {
  const keys = Object.keys(data);
  const optionKeys = Object.keys(otherOptions);
  let result = {};
  // console.log("data", data);

  keys.forEach((field) => {
    if (optionKeys.includes(field)) {
      if (data[field].length > 0) {
        const { id, ...rest } = data[field][0];
        result = { ...result, ...rest };
      }
    } else {
      result[field] = data[field];
      if (multiValueOptions.includes(field)) {
        const newFormattedData = [];
        data[field].forEach((row) => {
          newFormattedData.push(
            {
              ...row,
              ...(field === "sale_prices") && { checked: row.sale_price > 0 },
              ...(row.misys_id) && { misys_id: {
                value: parseInt(row.misys_id, 10),
                label: row.misys_name,
              } },
            }
          );
        });
        result[field] = newFormattedData;
      }
    }
    if (field === "customer" && data[field]) {
      result[field] = formatKey(data[field], camelCase);
    }
  });
  return formatKey(result, camelCase);
};

const transformQuoteBody = (data, method = "post") => {
  const formattedData = formatKey(data, snakeCase);

  const keys = Object.keys(formattedData);
  const optionKeys = Object.keys(otherOptions);
  const result = {};

  keys.forEach((field) => {
    result[field] = formattedData[field];
    if (multiValueOptions.includes(field)) {
      const newFormattedData = [];
      formattedData[field].forEach((row) => {
        const { id, ...rest } = row;
        const numberPattern = /^\d+\.?\d*$/;
        if (field !== "sale_prices" || (field === "sale_prices" && row.checked)) {
          newFormattedData.push(
            {
              ...rest,
              ...(method === "put" && numberPattern.test(id)) && { id },
              ...(row.misys_id) && { misys_id: row.misys_id.value },
            }
          );
        }
      });
      result[field] = newFormattedData;
    } else {
      optionKeys.forEach((option) => {
        if (otherOptions[option].includes(field)) {
          if (!result[option]) {
            result[option] = [{}];
          }
          result[option][0][field] = formattedData[field]; // always insert in first element
        }
      });
    }
  });
  // console.log("result", result);
  // Make sure unneeded fields are empty
  if (result.classification === "supplements") {
    if (result.quote_type === "capsule") {
      result.powder_options = [];
      result.powder_addons = [];
      result.cosmetics_options = [];
    } else {
      result.capsule_options = [];
      result.capsule_addons = [];
      result.cosmetics_options = [];
    }
  } else {
    result.powder_options = [];
    result.powder_addons = [];
    result.capsule_options = [];
    result.capsule_addons = [];
  }
  return result;
};

export default {
  getQueryString,
  formatKey,
  toDeepFormat,
  camelToSnakeCase,
  snakeToCamelCase,
  transformIngredientImportData,
  transformQuoteBody,
  transformQuoteData,
};
